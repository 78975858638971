var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-tile',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('h1',{staticClass:"heading-3 pa-0"},[_vm._v(" "+_vm._s(_vm.$t('configurations.apns.configuration:heading'))+" ")]),_c('ax-button',{attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"modal-content py-4"},[_c('v-stepper',{model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('div',{staticClass:"stepper-header-container px-8"},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.isNotFirstStep,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t('configurations.apns.configuration.intro:heading'))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t('configurations.apns.configuration.tokenExchange:heading'))+" ")])],1)],1),_c('v-stepper-items',{staticClass:"px-4"},[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.IconOttoApple,"alt":_vm.$t('configurations.apns.gettingStarted:phrase')}})],1),_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('configurations.apns.gettingStarted:phrase')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('configurations.apns.description:phrase'))+" ")])])],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.instructions:phrase' ))+" ")]),_c('v-row',[_c('v-col',{staticClass:"step-icon",attrs:{"cols":"1"}},[_c('img',{attrs:{"src":_vm.IconCSR,"alt":_vm.$t(
                    'configurations.apns.configuration.tokenExchange.step1.title:heading'
                  )}})]),_c('v-col',{staticClass:"step-content"},[_c('h2',{staticClass:"heading-3 step-title"},[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step1.title:heading' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step1.instruction:phrase' ))+" ")]),_c('p',[_c('ax-button',{attrs:{"outlined":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.mdiTrayArrowDown))]),_vm._v(" "+_vm._s(_vm.$t('general.buttons.download'))+" ")],1)],1)])],1),_c('v-row',[_c('v-col',{staticClass:"step-icon",attrs:{"cols":"1"}},[_c('img',{attrs:{"src":_vm.IconAPNS,"alt":_vm.$t(
                    'configurations.apns.configuration.tokenExchange.step2.title:heading'
                  )}})]),_c('v-col',{staticClass:"step-content"},[_c('h2',{staticClass:"heading-3 step-title"},[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step2.title:heading' ))+" ")]),_c('i18n',{attrs:{"path":"configurations.apns.configuration.tokenExchange.step2.instruction1:phrase","tag":"p"}},[_c('a',{attrs:{"href":"https://identity.apple.com/pushcert/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step2.instruction1:link' )))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step2.instruction2:phrase' ))+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"step-icon",attrs:{"cols":"1"}},[_c('img',{attrs:{"src":_vm.IconUpload,"alt":_vm.$t(
                    'configurations.apns.configuration.tokenExchange.step3.title:heading'
                  )}})]),_c('v-col',{staticClass:"step-content"},[_c('h2',{staticClass:"heading-3 step-title"},[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step3.title:heading' ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'configurations.apns.configuration.tokenExchange.step3.instruction:phrase' ))+" ")]),_c('p',[_c('ax-file-upload',{attrs:{"label":_vm.$t(
                      'configurations.apns.configuration.tokenExchange.step3.upload:button'
                    ),"required":""},on:{"files-selected":_vm.handleFileUpload},model:{value:(_vm.serverTokenFile),callback:function ($$v) {_vm.serverTokenFile=$$v},expression:"serverTokenFile"}})],1),_c('ax-text-field',{attrs:{"label":_vm.$t(
                    'configurations.apns.configuration.tokenExchange.step3.appleId:label'
                  ),"required":""},model:{value:(_vm.appleId),callback:function ($$v) {_vm.appleId=$$v},expression:"appleId"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-end pa-4"},[_c('div',[_c('ax-button',{staticClass:"mr-4",attrs:{"mode":"tertiary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),(_vm.isNotFirstStep)?_c('ax-button',{staticClass:"mr-4",attrs:{"mode":"secondary","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.back'))+" ")]):_vm._e(),(_vm.isFirstStep)?_c('ax-button',{attrs:{"mode":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.next'))+" ")]):_c('ax-button',{attrs:{"mode":"primary","disabled":_vm.isDisabled,"loading":_vm.isLoading},on:{"click":_vm.mutate}},[_vm._v(" Done ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }